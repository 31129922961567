<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="tranDate">FECHA</vs-th>
                    <vs-th sort-key="tranType">TIPO</vs-th>
                    <vs-th sort-key="tranRef">REFERENCIA</vs-th>
                    <vs-th sort-key="agencyName">RESPONSABLE</vs-th>
                    <vs-th sort-key="paymentName">MEDIO DE PAGO</vs-th>
                    <vs-th sort-key="PaymentDue">FECHA EST. DE PAGO</vs-th>
                    <vs-th sort-key="invTotal">Invoiced</vs-th>
                    <vs-th sort-key="rctTotal">Receipt</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].tranDate"> 
                                {{ data[indextr].tranDate | moment('timezone', 'Etc/GMT', 'll')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].tranType"> 
                                {{ data[indextr].tranType}}
                            </vs-td>
                            <vs-td :data="data[indextr].tranRef"> 
                                {{ data[indextr].tranRef}}
                            </vs-td>
                           
                            <vs-td :data="data[indextr].agencyName"> 
                                {{ data[indextr].agencyName }}
                            </vs-td>
                            
                            <vs-td :data="data[indextr].paymentName"> 
                                {{ data[indextr].paymentName }}
                            </vs-td>
                            <vs-td :data="data[indextr].PaymentDue"> 
                                {{ data[indextr].PaymentDue | moment('timezone', 'Etc/GMT', 'll')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].invTotal"> 
                                {{ $n(data[indextr].invTotal, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].rctTotal"> 
                                {{ $n(data[indextr].rctTotal, 'currency') }} 
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableAccountReceivable",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
            balance: 0,
            invTotal: 0,
            rcTotal:  0
        }
    },
    watch: {
        items(val){
            this.bookings = val
            
        },
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        }
    }
}
</script>