<template>
    <div class="dashboard bookings sales accountReceivable">
        <div class="head">
            <div class="title">Cuentas por cobrar</div>
        </div>
        <div class="summary" v-if="totalInv > 0">
            <div class="item">
                <div class="count">{{ $n(totalInv, 'currency') }}</div>
                <div class="name">Invoiced</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalRct, 'currency') }}</div>
                <div class="name">Received</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(pendingCost, 'currency') }}</div>
                <div class="name">Balance</div>
            </div>
        </div>

        <div class="box-white filter">
            <vs-collapse>
                <vs-collapse-item icon-arrow="expand_more">
                    <div slot="header">
                        Buscador
                    </div>
                    <div class="wrapper">
                        <div class="item">
                            <vs-input label="Referencia" v-model="reference"/>
                        </div>
                        <div class="item">
                            <div class="wrap">
                                <label for="">Fechas</label>
                                <vs-select  class="creSelect" v-model="typeDate">
                                    <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in filterDates" />
                                </vs-select>  
                            </div>
                            <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
                        </div>
                        
                        <div class="item">
                            <label for="">Estado Reservación</label>
                            <multiselect v-model="stBookings" :options="bookStatus" :multiple="true" :max-height="160" placeholder="" 
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCRIPTION" track-by="DESCRIPTION">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados seleccionados</span></template>
                            </multiselect>
                        </div>

                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Agencia" v-model="searchText" @keyup='loadSuggestions()'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <label for="">Agente</label>
                            <multiselect v-model="vendors" :options="consultants" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agentes seleccionados</span></template>
                            </multiselect>
                        </div>
                        
                        <div class="item">
                            <vs-button color="primary"  @click="search()" type="filled" icon="search"></vs-button>
                            <vs-button color="warning"  @click="reset()" type="border" icon="backspace" 
                                v-if="date != '' || reference != ''  || vendors.length > 0 || agencies.length > 0 || stBookings.length > 0 || services_status.length > 0
                                    || services_types != '' || voucher != '' || suppliers.length > 0 "></vs-button>
                        </div>
                    </div>
                    <div class="tags">
                        <div class="wrapper-tags">
                            <template v-for="(item, idx) in agencies">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item, 2)">{{ item.NAME }}</vs-chip>
                            </template>
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </div>

    
        <div class="title book-title">Reservaciones</div>

        <div class="bookings-wrapper" v-if="status == 'done'">
            <template v-for="(booking, idx) in bkAnalysis">
                <div :key="idx" class="card">
                    <div class="info">
                        <div class="item">
                            <span>Booking:</span>
                            {{ booking.reference }}
                        </div>
                        <div class="item">
                            <span>Name:</span>
                            {{ booking.name }}
                        </div>
                        <div class="item">
                            <span>Date In:</span>
                            {{ booking.dateIn   | moment('timezone', 'Etc/GMT', 'MM.DD.YYYY') }}
                        </div>
                        <div class="item">
                            <span>Date Out:</span>
                            {{ booking.dateOut   | moment('timezone', 'Etc/GMT', 'MM.DD.YYYY') }}
                        </div>
                        <div class="item">
                            <span>Agent:</span>
                            {{ booking.agentName }}
                        </div>
                        <div class="item">
                            <span>Vendor:</span>
                            {{ booking.consultantName }}
                        </div>
                        <div class="item cost">
                            <span>Precio BK:</span>
                            {{ $n(booking.price, 'currency') }}
                        </div>
                        <div :class="['item', booking.balance  == 0 ? 'success' : 'danger']">
                            <span>Saldo:</span>
                            {{ $n(booking.balance, 'currency') }}
                        </div>
                    </div>
                    <div class="wrapper">
                        <table-account-receivable :items="booking.items"></table-account-receivable>
                    </div>
                </div>
            </template>

        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
// import pagination from '@/components/Pagination.vue'
import tableAccountReceivable from '@/components/Tables/AccountReceivable.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
import BookingService from '@/services/BookingService'
import CatalogService from '@/services/CatalogService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "AccountReceivable",
    components: {
        // pagination,
        modal,
        tableAccountReceivable,
        inputDatePicker
    },
    data () {
        return {
            bkAnalysis: [],
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            reference: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stBookings: [],
            bookStatus: [],
            types: [],
            vendors: [],
            agencies: [],
            consultants:[],
            searchText: "",
            suggestiondata:[],
            typeDate: "travelDate",
            filterDates: [
                {
                    code: "dateOut",
                    name: "Date Out"
                },
                {
                    code: "travelDate",
                    name: "Travel Date"
                },
                {
                    code: "trnsDate",
                    name: "TRNS Date"
                },
                {
                    code: "PaymentDue",
                    name: "Payment Due"
                }
            ],
            totalInv:  0,
            totalRct: 0,
            pendingCost:  0,
            suppliers:[],
            loadSuggestion: 1,
            bookings: [],
            balance: 0,
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.setDefaultValues();
        await this.getAccountsReceivable(); 
        await this.getBookingStatus();
        await this.getConsultants();
        await this.getServiceStatus();
        await this.getServiceTypes();
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getAccountsReceivable(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getAccountsReceivable({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "reference" : this.reference, //opcional
                "agencies" :    this.agencies, //opcional
                "consultants" : this.vendors, //opcional
                "booking_status": this.stBookings,
                "typeDate": this.typeDate,
            });            
            if((response.status/100) == 2){
                this.bkAnalysis = response.data
            }
            
            this.totalInv = 0;
            this.totalRct = 0;
            this.pendingCost = 0;
            this.bkAnalysis.forEach(item => {
                this.totalInv += item.totalInv;
                this.totalRct += item.totalRct;
                this.pendingCost += item.balance;
            })

            this.LOADING_STATUS(false);
        },
        async getBookingStatus(){
            this.LOADING_STATUS(true);
            this.bookStatus = await CatalogService.getBookingStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceStatus(){
            this.LOADING_STATUS(true);
            this.srvStatus = await CatalogService.getServiceStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceTypes(){
            this.LOADING_STATUS(true);
            this.srvTypes = await CatalogService.getServiceTypes({});            
            this.LOADING_STATUS(false);
        },
        async getConsultants(){
            this.LOADING_STATUS(true);
            this.consultants = await CatalogService.getConsultants({});            
            this.LOADING_STATUS(false);
        },
        handlePagination (_paged) {
            this.filter.page = _paged    
            // this.retrieveProperties()
        },
        search() {
            this.getAccountsReceivable()
        },
        reset() {
            this.date = "";
            this.reference = "";
            this.agencies  = [];
            this.vendors   = [];
            this.stBookings = [];
            this.setDefaultValues();
            this.getAccountsReceivable();
        },
        setDefaultValues(){
            this.stBookings = [ 
                { CODE: 'IN', DESCRIPTION: "Invoiced" }, 
                { CODE: 'PP', DESCRIPTION: "Partial Pay"}, 
                { CODE: 'PA', DESCRIPTION: "Paid"}
            ];
        },
        async loadSuggestions(){
            this.suggestiondata = [];
            if(this.searchText != ''){
                let response = await CatalogService.getAgencies({ search: this.searchText})
                console.log('response', response)
                if((response.status/100) == 2){
                    this.suggestiondata = response.data
                    // this.searchText = ''
                }
            }
        },
        itemSelected(index){
            if(this.agencies.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                this.agencies.push(this.suggestiondata[index])
            }
            
            this.suggestiondata = [];
            this.searchText = ''
        },
        removeAgency (item) {
            this.agencies.splice(this.agencies.indexOf(item), 1)
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>